<template>
  <AView
    :value="value"
    :hasFilters="!!filter.search"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    :payload="{
      from: value,
    }"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            right
            :label="'DocAssistantItems'"
            :icon="value.view.display.icon"
            @action="(action) => action.handler({ from: value })"
          ></FormSection>
        </v-col>
      </v-row>

      <v-row class="scroll-row d-flex flex-grow-1">
        <v-col cols="12" class="pa-0 ma-0 d-flex flex-column flex-grow-1">
          <v-virtual-scroll
            class="pa-0 ma-0"
            :bench="5"
            :items="docAssistantItems"
            height="630"
            item-height="260"
            @scroll="onScroll"
          >
            <template v-slot:default="{ item }">
              <DocAssistantGenerationItem :value="item"></DocAssistantGenerationItem>
            </template>
          </v-virtual-scroll>
        </v-col>
      </v-row>
      <Pagination
        :value="pagination"
        :total="docAssistantItems.length"
        :show="showMore"
        @onLoadMore="
          $store.dispatch('DocAssistantItemsStore/GetDocAssistantItemsList', {
            next: true,
          })
        "
      ></Pagination>
    </template>

    <template v-slot:actions="{}">
      <DocAssistantItemsFilter v-model="filter"> </DocAssistantItemsFilter>
    </template>
  </AView>
</template>
      
      
      
    <script>
import { mapState } from "vuex";
import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import Pagination from "../../../../../components/wad/atoms/common/Pagination.vue";
import DocAssistantGenerationItem from '../components/DocAssistantGenerationItem.vue';
//   import DocAssistantItemsFilter from "../../../../../components/wad/molecules/knowledgeBase/docAssistantItems/DocAssistantItemsFilter.vue";



export default {
  name: "DocAssistantItemsList",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
    Pagination,
    DocAssistantGenerationItem
    //   DocAssistantItemsFilter,
  },
  computed: {
    ...mapState("DocAssistantStore", ["docAssistantItems", "pagination"]),
  },
  async created() {
    this.$store.dispatch("DocAssistantStore/history", {
      reset: true,
    });

    this.updateInterval = setInterval(() => {
      this.$store.dispatch("DocAssistantStore/history", {
        reset: true,
        pagination: this.pagination,
      });
    }, 2000);
  },
  data() {
    return {
      filter: {},
      showMore: false,
      updateInterval: undefined,
    };
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
  methods: {
    onHover() {},
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
    onFileOpen(file) {
      file.u_type = "documents";
      this.$store.dispatch("FileStore/openFileDetails", {
        from: this.value,
        file,
      });
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        this.$store.dispatch(
          "DocAssistantItemsStore/GetDocAssistantItemsList",
          {
            filter: newVal,
            reset: true,
          }
        );
      },
      deep: true,
    },
  },
};
</script>
      
      
      
  <style scoped lang="scss" >
.scroll-row {
  height: calc(100% - 110px);
}

.docAssistantItem-preview-card {
  border-radius: 4px;

  &::after {
    border-radius: 4px;
  }

  .docAssistantItem-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    .docAssistantItem-preview {
      max-height: 130px;
      border-radius: 4px;
    }
  }
}
</style>